import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Home from "./pages/Home.js";
import Logo from "./assets/images/logo.png";
import Edit from "./pages/Edit";
import React from "react";

function App() {
  const photos = [Logo];

  return (
    <div className="App">
      <header>
        <img src={photos[0]} className="logo" alt="" />
      </header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/edit" element={<Edit />} />
        </Routes>
      </BrowserRouter>
      <footer></footer>
    </div>
  );
}

export default App;
