import React from "react";
import { v4 as uuid } from "uuid";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "../styles/carousel.css";
import "@splidejs/react-splide/css";

import img1 from "../assets/images/IMG_0475.JPG";
import img2 from "../assets/images/IMG_0476.JPG";
import img3 from "../assets/images/IMG_0477.JPG";

const Carousel = () => {
  let slides = [img1, img2, img3];

  return (
    <div className="container">
      <Splide
        options={{
          type: "loop",
          width: "40rem",
          height: "20rem",
          autoplay: true,
          interval: 5000,
          arrows: false,
          pagination: false,
        }}
      >
        {slides.map((image) => {
          return (
            <SplideSlide className="slide" key={uuid().slice(0, 3)}>
              <img className="slide-img" src={image} alt="shirt" />
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
};

export default Carousel;
