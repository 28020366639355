import React from "react";
import Carousel from "../components/Carousel";
import ProductList from "../components/ProductList";
import "../styles/home.css";
import ShoppingCart from "../components/ShoppingCart";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      CartItems: [],
      updateCartRef: "",
      cartColor: "",
    };
  }

  HomeCallback = (item) => {
    this.state.CartItems.push(item);
  };

  removeFromCart = (cartId) => {
    this.state.CartItems.splice(
      this.state.CartItems.findIndex((item) => {
        return item.id === cartId;
      }),
      1
    );
  };

  bindUpdateCart = (func) => {
    this.setState({
      updateCartRef: func,
    });
  };

  render() {
    return (
      <div>
        <ShoppingCart
          cartItems={this.state.CartItems}
          removeCB={this.removeFromCart}
          bindUpdateCB={this.bindUpdateCart}
          color={this.state.cartColor}
        />
        <Carousel className="carousel" />
        <section className="info-container">
          <h3 className="marketing">
            Here at Shirtshack423 we not only customize shirts but also hats,
            mugs, and bags. We take pride in creating quality merchandise at a
            low price.
          </h3>
        </section>
        <ProductList
          hcb={this.HomeCallback}
          updateCartFromHomeCB={this.state.updateCartRef}
          colorHomeCB={this.colorHomeCB}
        />
      </div>
    );
  }
}

export default Home;
