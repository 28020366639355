import React from "react";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import "../styles/shopping-cart.css";
import ProductList from "./ProductList";
import axios from "axios";

class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);
    console.log(props.cartItems);
    this.state = {
      open: false,
      total: function () {
        let total = 0;
        for (let i = 0; i < props.cartItems.length; i++) {
          props.cartItems[i].id === "custom"
            ? (total += 0)
            : props.cartItems[i].templateName === "mug"
            ? (total += parseFloat(
                props.cartItems[i].mugPrice * props.cartItems[i].qty
              ))
            : props.cartItems[i].templateName === "shirt"
            ? (total += parseFloat(
                props.cartItems[i].shirtPrice * props.cartItems[i].qty
              ))
            : (total += parseFloat(
                props.cartItems[i].hatPrice * props.cartItems[i].qty
              ));
        }
        if (isNaN(total)) {
          return 0;
        } else {
          return total;
        }
      },
    };

    this.updateCart = () => {
      console.log("cart updated");
      this.forceUpdate();
    };
    props.bindUpdateCB(this.updateCart);
  }

  render() {
    return (
      <div
        className={`cart-container ${
          this.state.open ? "cart-container-show" : ""
        }`}
      >
        <div className="inner">
          <FontAwesomeIcon
            icon={faShoppingCart}
            onClick={() => {
              this.setState({
                open: !this.state.open,
              });
            }}
            className="cart-icon"
            alt=""
          />
          <span className="number-in-cart">{this.props.cartItems.length}</span>
          <span
            className="mobile-close-cart"
            onClick={() => {
              this.setState({
                open: !this.state.open,
              });
            }}
          >
            X
          </span>
          <h1>Cart</h1>
          <ProductList
            cart={true}
            cartItems={this.props.cartItems}
            removeCB={this.props.removeCB}
            cartUpdateCB={this.updateCart}
            color={this.props.color}
          />
          <h1 className="total">{`Total: $${this.state.total()}`}</h1>

          <PaymentForm
            formProps={{ className: "payment-form" }}
            amount={this.state.total()}
            applicationId="sandbox-sq0idb-fn2P85lw4mhrsIItOZ0EsA"
            locationId="LA6P3EE8XEDP5"
            cardTokenizeResponseReceived={async (token, buyer) => {
              await axios.post(
                "https://shirt-shack-backend.herokuapp.com/pay",
                {
                  sourceId: token.token,
                  amount: this.state.total(),
                }
              );
            }}
          >
            <CreditCard />
          </PaymentForm>
        </div>
      </div>
    );
  }
}

export default ShoppingCart;
