import React from "react";
import axios from "axios";
import "../styles/edit.css";
import { v4 as uuid } from "uuid";
import ProductList from "../components/ProductList";

class Edit extends React.Component {
  constructor() {
    super();
    this.state = {
      id: uuid().slice(0, 3),
      name: "",
      image: "",
      shirtPrice: 0,
      mugPrice: 0,
      hatPrice: 0,
    };
  }

  parentCB = (data) => {
    this.setState({
      Posts: data,
    });
  };

  handleInputChange = (e) => {
    if (e.target.name === "image") {
      this.setState({ [e.target.name]: e.target.files[0] });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      id: uuid().slice(0, 3),
    });

    const data = new FormData();
    data.append("id", this.state.id);
    data.append("name", this.state.name);
    data.append("image", this.state.image);
    data.append("shirtPrice", this.state.shirtPrice);
    data.append("mugPrice", this.state.mugPrice);
    data.append("hatPrice", this.state.hatPrice);
    console.log(data);

    axios
      .post("https://shirt-shack-backend.herokuapp.com/items", data)
      .then((res) => console.log(res))
      .then(this.forceUpdate())
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="wrapper-edit">
        <form className="item-form">
          <label htmlFor="name">Item name</label>
          <input
            type="text"
            id="name"
            name="name"
            onChange={this.handleInputChange}
            placeholder="Item Name"
          ></input>
          <label htmlFor="shirtPrice">Price For Shirt With Design</label>
          <input
            type="number"
            min="1"
            step="any"
            id="shirtPrice"
            name="shirtPrice"
            onChange={this.handleInputChange}
          ></input>
          <label htmlFor="mugPrice">Price For Mug With Design</label>
          <input
            type="number"
            min="1"
            step="any"
            id="mugPrice"
            name="mugPrice"
            onChange={this.handleInputChange}
          ></input>
          <label htmlFor="hatPrice">Price For Hat With Design</label>
          <input
            type="number"
            min="1"
            step="any"
            id="hatPrice"
            name="hatPrice"
            onChange={this.handleInputChange}
          ></input>
          <label htmlFor="image">Image</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={this.handleInputChange}
            accept="image/png"
          ></input>
          <button type="submit" onClick={this.handleSubmit}>
            Submit
          </button>
        </form>
        <ProductList edit={true} />
      </div>
    );
  }
}

export default Edit;
