import React from "react";
import ProductCard from "./ProductCard";
import "../styles/product-list.css";
import axios from "axios";

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Posts: [],
    };
  }

  componentDidMount() {
    this.renderPosts();
  }

  renderPosts = async () => {
    try {
      const res = await axios.get(
        "https://shirt-shack-backend.herokuapp.com/items"
      );
      const posts = res.data;
      console.log(posts);

      this.setState({
        Posts: posts,
      });
    } catch (err) {
      console.log(err);
    }
    if (this.props.cart) {
      this.setState({
        Posts: this.props.cartItems,
      });
    }
  };

  parentCB = (data) => {
    this.setState({
      Posts: data,
    });
  };

  ListCallback = (item) => {
    this.props.hcb(item);
    console.log(item.color);
  };

  removeFromCart = (cartId) => {
    this.props.removeCB(cartId);
  };

  colorCB = (color) => {
    this.props.colorHomeCB(color);
  };

  render() {
    const posts = this.state.Posts?.map((post) => (
      <ProductCard
        name={post.name}
        image={`data:image/png;base64,${post.image}`}
        edit={this.props.edit}
        id={post.id}
        pcb={this.parentCB}
        lcb={this.ListCallback}
        cart={this.props.cart}
        removeCartCB={this.removeFromCart}
        color={post.color}
        cartUpdateCB={this.props.cartUpdateCB}
        homeListCartUpdateCB={this.props.updateCartFromHomeCB}
        qty={post.qty}
        size={post.size}
        shirtPrice={post.shirtPrice}
        mugPrice={post.mugPrice}
        hatPrice={post.hatPrice}
        key={post.id + Math.random()}
        template={post.template}
        templateName={post.templateName}
        designLeft={post.designLeft}
        designTop={post.designTop}
        custom={post.custom}
        designWidth={post.designWidth}
      />
    ));
    if (!this.props.cart && !this.props.edit) {
      posts.push(
        <ProductCard
          custom={true}
          name="Add Your Custom Design"
          key="custom"
          lcb={this.ListCallback}
          homeListCartUpdateCB={this.props.updateCartFromHomeCB}
        />
      );
    }
    return <div className="wrapper">{posts}</div>;
  }
}

export default ProductList;
