import React from "react";
import axios from "axios";
import shirtTemplate from "../assets/images/shirt-template.png";
import mugTemplate from "../assets/images/mug.png";
import hatTemplate from "../assets/images/hat.png";
import "../styles/product-card.css";

let dragging = false;
let designLeft = "0px";
let designTop = "20px";
let previousTouch;
let designWidth;

document.addEventListener("mouseup", () => {
  dragging = false;
});
document.addEventListener("touchend", () => {
  previousTouch = null;
  dragging = false;
});

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      color: "white",
      customImage: "",
      quantity: 1,
      size: "M",
      template: shirtTemplate,
      templateName: "shirt",
    };
    this.imageFile = "";
    try {
      this.imageFile = props.image;
    } catch {}
  }

  changeColor = (e) => {
    this.setState({
      color: e.target.className.slice(10, e.target.className.length),
    });
  };
  changeItem = (e) => {
    if (e.target.className.slice(9, e.target.className.length) === "shirt") {
      this.setState({
        template: shirtTemplate,
        templateName: "shirt",
      });
    } else if (
      e.target.className.slice(9, e.target.className.length) === "mug"
    ) {
      this.setState({
        template: mugTemplate,
        templateName: "mug",
      });
    } else if (
      e.target.className.slice(9, e.target.className.length) === "hat"
    ) {
      this.setState({
        template: hatTemplate,
        templateName: "hat",
      });
    }
  };

  handleDesignSize = (e) => {
    const design = e.target.parentElement.parentElement.firstChild.firstChild;
    if (e.target.name === "S") {
      design.style.maxWidth = "2rem";
      designWidth = "2rem";
    } else if (e.target.name === "M") {
      design.style.maxWidth = "4rem";
      designWidth = "4rem";
    } else if (e.target.name === "L") {
      design.style.maxWidth = "6rem";
      designWidth = "6rem";
    }
  };

  onDrag = (e) => {
    console.log(e.type);
    if (dragging && e.type === "mousemove") {
      designLeft = `${parseInt(e.target.style.left) + e.movementX}px`;
      designTop = `${parseInt(e.target.style.top) + e.movementY}px`;
      e.target.style.left = designLeft;
      e.target.style.top = designTop;
    } else if (dragging && e.type === "touchmove") {
      if (previousTouch) {
        designLeft = `${
          parseInt(e.target.style.left) +
          (e.touches[0].pageX - previousTouch.pageX)
        }px`;
        designTop = `${
          parseInt(e.target.style.top) +
          (e.touches[0].pageY - previousTouch.pageY)
        }px`;

        e.target.style.left = `${
          parseInt(e.target.style.left) +
          (e.touches[0].pageX - previousTouch.pageX)
        }px`;

        e.target.style.top = `${
          parseInt(e.target.style.top) +
          (e.touches[0].pageY - previousTouch.pageY)
        }px`;
      }
      previousTouch = e.touches[0];
    }
  };

  deleteProduct = (e) => {
    axios
      .delete("https://shirt-shack-backend.herokuapp.com/items", {
        data: { id: e.target.id },
      })
      .then((res) => {
        this.props.pcb(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleUpload = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      this.setState({ customImage: reader.result });
      this.imageFile = reader.result;
    };
  };

  handleQty = (e) => {
    if (e.target.className === "dec") {
      if (this.state.quantity - 1 >= 1) {
        this.setState({ quantity: parseInt(this.state.quantity) - 1 });
      }
    } else if (e.target.className === "inc") {
      this.setState({ quantity: parseInt(this.state.quantity) + 1 });
    }
  };

  handleSize = (e) => {
    this.setState({
      size: e.target.className.slice(9),
    });
  };

  AddToCart = (e) => {
    this.props.lcb({
      id: e.target.id,
      name: e.target.name,
      image: this.imageFile.slice(22),
      color: this.state.color,
      qty: this.state.quantity,
      size: this.state.size,
      shirtPrice: this.props.custom ? 0 : this.props.shirtPrice,
      mugPrice: this.props.custom ? 0 : this.props.mugPrice,
      hatPrice: this.props.custom ? 0 : this.props.hatPrice,
      template: this.state.template,
      templateName: this.state.templateName,
      designLeft: designLeft,
      designTop: designTop,
      custom: this.props.custom,
      designWidth: designWidth,
    });
    this.props.homeListCartUpdateCB();
  };

  removeFromCart = (e) => {
    this.props.removeCartCB(e.target.id);
    this.props.cartUpdateCB();
  };

  onQuantityChange = (e) => {
    if (e.target.value >= 1) {
      this.setState({
        quantity: e.target.value,
      });
    } else if (isNaN(e.target.value)) {
      this.setState({
        quantity: 1,
      });
    }
  };

  render() {
    return (
      <div
        className={`card ${this.props.cart ? "cart-edit" : ""}`}
        id={this.props.cart ? this.props.templateName : this.state.templateName}
      >
        {this.props.cart && (
          <button
            className="delete"
            id={this.props.id}
            onClick={this.removeFromCart}
          >
            &#10005;
          </button>
        )}

        {this.props.edit && (
          <button
            className="delete"
            id={this.props.id}
            onClick={this.deleteProduct}
          >
            &#10005;
          </button>
        )}

        <div
          className="image-section"
          style={{
            backgroundImage: `url(${
              this.props.cart ? this.props.template : this.state.template
            })`,
            backgroundColor: this.props.cart
              ? this.props.color
              : this.state.color,
          }}
        >
          <span className="design">
            <img
              src={
                this.props.cart
                  ? this.props.image
                  : this.props.custom
                  ? this.state.customImage
                  : this.props.image
              }
              alt={
                this.props.custom
                  ? `shirt design custom`
                  : `shirt design ${this.props.id}`
              }
              style={{
                left: `${!this.props.cart ? "0px" : this.props.designLeft}`,
                top: `${!this.props.cart ? "20px" : this.props.designTop}`,
                maxWidth: `${
                  !this.props.cart ? "6rem" : this.props.designWidth
                }`,
              }}
              onMouseDown={
                !this.props.cart &&
                !this.props.edit &&
                (() => {
                  dragging = true;
                })
              }
              onMouseMove={!this.props.cart && !this.props.edit && this.onDrag}
              draggable={false}
              onTouchStart={
                !this.props.cart &&
                !this.props.edit &&
                (() => {
                  dragging = true;
                })
              }
              onTouchMove={!this.props.cart && !this.props.edit && this.onDrag}
            />
          </span>
          {!this.props.cart && !this.props.edit && (
            <div className="design-size">
              <button onClick={this.handleDesignSize} name="S">
                S
              </button>
              <button onClick={this.handleDesignSize} name="M">
                M
              </button>
              <button onClick={this.handleDesignSize} name="L">
                L
              </button>
            </div>
          )}
        </div>
        {!this.props.cart && (
          <div className="item-selector">
            <button className="item-btn shirt" onClick={this.changeItem}>
              Shirt
            </button>
            <button className="item-btn mug" onClick={this.changeItem}>
              Mug
            </button>
            <button className="item-btn hat" onClick={this.changeItem}>
              Hat
            </button>
          </div>
        )}
        {!this.props.cart && !this.props.edit && (
          <div className="color-selector">
            <button
              className="color-btn white"
              onClick={this.changeColor}
            ></button>
            <button
              className="color-btn black"
              onClick={this.changeColor}
            ></button>
            <button
              className="color-btn grey"
              onClick={this.changeColor}
            ></button>
            <button
              className="color-btn navy"
              onClick={this.changeColor}
            ></button>
            <button
              className="color-btn blue"
              onClick={this.changeColor}
            ></button>
            <button
              className="color-btn brown"
              onClick={this.changeColor}
            ></button>
            <button
              className="color-btn green"
              onClick={this.changeColor}
            ></button>
            <button
              className="color-btn pink"
              onClick={this.changeColor}
            ></button>
            <button
              className="color-btn purple"
              onClick={this.changeColor}
            ></button>
          </div>
        )}
        {this.state.templateName === "shirt" &&
          !this.props.cart &&
          !this.props.edit && (
            <div className="size-selector">
              <button className="size-btn S" onClick={this.handleSize}>
                S
              </button>
              <button className="size-btn M" onClick={this.handleSize}>
                M
              </button>
              <button className="size-btn L" onClick={this.handleSize}>
                L
              </button>
              <button className="size-btn XL" onClick={this.handleSize}>
                XL
              </button>
            </div>
          )}
        {this.props.cart
          ? this.state.templateName === "shirt"
          : this.props.templateName && (
              <h3>{`Size: ${
                this.props.cart ? this.props.size : this.state.size
              }`}</h3>
            )}
        {!this.props.custom && (
          <h3>{`$${parseFloat(
            this.state.templateName === "shirt"
              ? this.props.shirtPrice * this.state.quantity
              : this.state.templateName === "mug"
              ? this.props.mugPrice * this.state.quantity
              : this.props.hatPrice * this.state.quantity
          )}`}</h3>
        )}

        {this.props.custom && !this.props.cart && (
          <>
            <label className="custom-img-lbl" htmlFor="custom-img">
              Upload Design
            </label>
            <input
              className="custom-img-btn"
              id="custom-img"
              type="file"
              onChange={this.handleUpload}
            ></input>
          </>
        )}

        {!this.props.cart && !this.props.edit && (
          <button
            className="add"
            onClick={this.AddToCart}
            name={this.props.name}
            id={this.props.id}
            imagefile={this.props.image}
            descriptionstr={this.props.description}
          >
            Add to cart
          </button>
        )}
        {!this.props.cart && !this.props.edit && (
          <div className="qty-selector">
            <button className="dec" onClick={this.handleQty}>
              -
            </button>
            <input
              type="text"
              value={this.state.quantity}
              onChange={this.onQuantityChange}
              className="quantity-input"
            />
            <button className="inc" onClick={this.handleQty}>
              +
            </button>
          </div>
        )}
        <h1 className="title">{this.props.name}</h1>
      </div>
    );
  }
}

export default ProductCard;
